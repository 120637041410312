import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

interface Channel888Props {
  module: any;
  sendBanner: any;
}

const Channel888: React.FC<Channel888Props> = ({ module, sendBanner }) => {
  const largeBackgroundImageUrl = 
  module?.galleryContent?.length ? module.galleryContent[0]?.fields?.largeBackgroundImage?.fields?.file?.url : null;

const mobileBackgroundImageUrl = 
  module?.galleryContent?.length ? module.galleryContent[0]?.fields?.mobileBackgroundImage?.fields?.file?.url : null;
  
  // const largeBackgroundImageUrl =   module?.galleryContent[0]?.fields?.largeBackgroundImage?.fields?.file?.url;
  // const mobileBackgroundImageUrl =  module?.galleryContent[0]?.fields?.mobileBackgroundImage?.fields?.file?.url;

  return (
    <>
      <section className="bg-white py-8">
        <div className="container m-auto">
          {/*Heading Copntent*/}
          <div className="text-center mb-8">
           {module?.heading &&  <h2 className="sky-h3 md:sky-h3-black sky-text-midnight md:py-4 px-4">
              {module?.heading}
            </h2>
            }
            <p className="text-base text-gray-600">
              {documentToReactComponents(module?.detailsdescription)}
            </p>
          </div>

          <div className="w-full px-4">
  {/* Desktop Image */}
  {largeBackgroundImageUrl && <img
    alt="Satellite Diagram"
    className="w-full h-auto hidden md:block"
    src={largeBackgroundImageUrl}
  />}
 

  {/* Mobile Image */}
  {mobileBackgroundImageUrl &&  <img
    alt="Satellite Diagram"
    className="w-full h-auto block md:hidden"
    src={mobileBackgroundImageUrl}
  />}

</div>          
                </div>
      </section>
    </>
  );
};

export default Channel888;
