import {  gql } from '@apollo/client';

// Define the GraphQL mutation query
export const UPDATE_ELABEL = gql`
  mutation UpdateElabel($eLabelTokenNumber: String!, $consignmentId: String!, $fromAddress: String!, $toAddress: String!, $trackingID: String!) {
    updateElabel(input: {
      eLabelTokenNumber: $eLabelTokenNumber
      consignmentId: $consignmentId
      fromAddress: $fromAddress
      toAddress: $toAddress
      trackingID: $trackingID
    }) {
      statusCode
    }
  }
`;