import React from "react";
import { Button } from "@sky-tv-group/components";
import { segment } from "@sky-tv-group/shared";

interface BuildYourOwnProps {
    module: any;
}

const BuildYourOwn: React.FC<BuildYourOwnProps> = ({ module }) => {

    const [desktop, setDesktop] = React.useState(false);

    const cardData = module.componentCards[0].fields;
    const packageWindowResize = () => {
        if (window.innerWidth > 767) {
            setDesktop(true);
        } else {
            setDesktop(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', packageWindowResize);
        packageWindowResize();
        return () => window.removeEventListener('resize', packageWindowResize);
    }, []);

    return (<div className="p-5 bg-white rounded-lg border-2 border-neutral-400"
        style={{
            display: 'flex',
            flexDirection: desktop ? 'row' : 'column',
            width: desktop ? '68%' : '',
            gap: desktop ? '24px' : '12px',
            marginLeft: desktop ? '15%' : '8px',
            marginRight: desktop ? '15%' : '8px',
            marginBottom: '32px',
        }}
    >
        <img className="relative" src={ cardData?.cardImageUrl?.fields?.file?.url} />
        <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="text-[28px] font-skyBlack font-black leading-[30.80px]">{cardData?.cardTitle}</div>
            <div className="text-base leading-tight">{cardData?.cardDescription} </div>
            <Button
                onClick={() => {
                    window.open(cardData?.linkUrl, '_blank');
                    segment.promotionClicked(cardData?.linkText, "No Image", "Build Your Own");
                }}
                variant="secondary"
                colorMode="pure"
            >
                {cardData?.linkText}
            </Button>
        </div>
    </div>);
}


export default BuildYourOwn;