import React from 'react';
import { gql, useMutation } from '@apollo/client';

export const CREATE_LABEL = gql`
  mutation CreateLabel(
    $carrier: String!
    $senderReference1: String!
    $senderReference2: String!
    $accountNumber: String!
    $senderDetails: SenderDetailsInput!
    $receiverDetails: ReceiverDetailsInput!
    $deliveryAddress: AddressInput!
    $parcelDetails: [ParcelDetailInput!]!
    $addressId:String!
    $fromAddress:String!
    $toAddress : String!
    $tokenNumber:String!
  ) {
    createLabel(
      input: {
        carrier: $carrier
        sender_reference1: $senderReference1
        sender_reference2: $senderReference2
        account_number: $accountNumber
        sender_details: $senderDetails
        receiver_details: $receiverDetails
        delivery_address: $deliveryAddress
        parcel_details: $parcelDetails
        address_id:$addressId
        fromAddress:$fromAddress
        toAddress:$toAddress
        tokenNumber:$tokenNumber
      }
    ) {
      consignment_id
      consignment_status
      message_id
      success
      tracking_id
      toAddress
      fromAddress
    }
  }
`;

// export const CREATE_LABEL = gql`
//   mutation CreateLabel {
//     createLabel(
//       input: {
//         carrier: "COURIERPOST"
//         sender_reference1: "Order Number 56452"
//         sender_reference2: "Sales"
//         account_number: "92306955"
//         sender_details: {
//           company_name: "Sky New Zealand"
//           name: "Tamas"
//           site_code: 94371
//           phone: "+64226074853"
//           email: "help@sky.co.nz"
//         }
//         receiver_details: {
//           name: "Tamas"
//           phone: "+64226074853"
//           email: "help@sky.co.nz"
//         }
//         pickup_address: { dpid: 2627508, country_code: "NZ" }
//         delivery_address: { dpid: 1371032, country_code: "NZ" }
//         parcel_details: {
//           service_code: "CPOLP"
//           return_indicator: "OUTBOUND"
//           description: "Medium Box"
//           dimensions: {
//             length_cm: 30
//             width_cm: 30
//             height_cm: 30
//             weight_kg: 3
//           }
//         }
//       }
//     ) {
//       consignment_id
//       message_id
//       success
//     }
//   }
// `;
