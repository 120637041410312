import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { SEARCH_ADDRESS } from '../../graphql/queries/searchAddress';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_LABEL } from '../../graphql/mutation/createLabel';

import StepsComponent from './StepsComponent';
import { ADDRESS_DETAILS } from '../../graphql/queries/getAddressDetails';
import { PARCEL_LABEL_STATUS } from '../../graphql/queries/getLabelStatus';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../content-modules/satellite-conent/Info';
import {
  CARRIER,
  SENDER_REFERENCE_1_PREFIX,
  SENDER_REFERENCE_2,
  ACCOUNT_NUMBER,
  DELIVERY_ADDRESS,
  PICKUP_ADDRESS,
  RECEIVER_DETAILS_AUCKLAND,
  RECEIVER_DETAILS_WELLINGTON,
  RECEIVER_DETAILS_AUCKLAND_DPID,
  RECEIVER_DETAILS_WELLINGTON_DPID,
  RECEIVER_DETAILS_AUCKLAND_ADDRESS,
  RECEIVER_DETAILS_WELLINGTON_ADDRESS,
  SITE_CODE,
} from '../../constants'; // Import the constants
import { UPDATE_ELABEL } from '../../graphql/mutation/updateLabel';
import { segment } from '@sky-tv-group/shared';
import { filter, isEmpty } from 'lodash';
import { getParcelsByFilter } from '../../helper';
import { ReactComponent as ElableGreenTick } from '../../assets/icons/elable-green-tick.svg'

interface Address {
  id: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
}

interface PageProps {
  module: any;
  validateAccountData: any;
}

const ReturnBagConfirmAddress: React.FC<PageProps> = ({
  module,
  validateAccountData,
}) => {
  const [address, setAddress] = useState('');
  const [addressObj, setAddressObj] = useState<Address | null>(null);
  const [debouncedAddress, setDebouncedAddress] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState('');
  const [confirmAddressLoading, setConfirmAddressLoading] = useState(false);
  // const { addToast } = useToastContainer();
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [isConfirm, setIsConfirm] = useState(true);
  const [postCode, setPostCode] = useState<number>();

  const [updateElabel] = useMutation(UPDATE_ELABEL);
  // Retrieve accountData from the state
  const accountResponse = location.state?.accountData;
  const accountVerification = location.state?.accountVerification;
  const [accountData, setAccountData] = useState(
    validateAccountData ?? accountResponse,
  );

  const { loading, error, data } = useQuery(SEARCH_ADDRESS, {
    variables: { query: debouncedAddress, count: 5 },
    skip: !debouncedAddress, // Prevent running query when input is empty
  });

  // const {
  //   data: addressData,
  //   loading: addressLoading,
  //   error: addressError,
  // } = useQuery(ADDRESS_DETAILS, {
  //   variables: { id: selectedAddressId + '' },
  //   skip: !selectedAddressId, // Prevent query execution if ID is missing
  // });
  const [
    createLabel,
    { loading: createLabelLoading, error: createLabelError },
  ] = useMutation(CREATE_LABEL);

  const addressSessionDetails = sessionStorage.getItem('addressDetails');
  const addressDetails =
    addressSessionDetails && JSON.parse(addressSessionDetails);

  useEffect(() => {
    // Handle account data validation
    if (validateAccountData) {
      setAccountData(validateAccountData);
    } else if (!accountData) {
      navigate('/returnbagprocess');
    }

    if (addressSessionDetails) {
      setFromAddress(addressDetails?.fromAddress);
      setAddress(addressDetails?.fromAddress);
      setToAddress(addressDetails?.toAddress);
      setSelectedAddressId(addressDetails?.addressId);
      setIsConfirm(false);
    } else if (addressObj) {
      setFromAddress(addressObj?.address);
      const extractedPostalCode = addressObj?.postalCode?.match(/\d+/);
      if (extractedPostalCode) {
        const postCode = parseInt(extractedPostalCode[0], 10); // Convert the matched string to an integer
        setPostCode(postCode);
        const address =
          postCode < 4400
            ? RECEIVER_DETAILS_AUCKLAND_ADDRESS
            : RECEIVER_DETAILS_WELLINGTON_ADDRESS;
        setToAddress(address ?? RECEIVER_DETAILS_AUCKLAND_ADDRESS);
      }

      if (addressObj?.id) setSelectedAddressId(addressObj?.id);
    }
  }, [validateAccountData, addressObj, addressSessionDetails]);

  // Debounce input to avoid excessive API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAddress(address);
    }, 300); // Adjust delay as needed

    return () => clearTimeout(handler);
  }, [address]);

  const handleSelectAddress = (
    selectedAddress: string,
    selectedAddressObj: any,
  ) => {
    sessionStorage.removeItem('addressDetails');
    setAddress(selectedAddress);
    setAddressObj(selectedAddressObj);
    setShowDropdown(false);
    setIsConfirm(false);
  };

  const navigate = useNavigate(); // Initialize navigate

  // const [
  //   getParcelLabelStatus,
  //   {
  //     data: parcelLabelData,
  //     loading: parcelLabelLoading,
  //     error: parcelLabelError,
  //   },
  // ] = useLazyQuery(PARCEL_LABEL_STATUS);

  const handleCreateLabel = async () => {
    const parsal_details = await getParcelsByFilter(
      accountData?.returnBoxesCount ?? 1
    );

    try {
      const { data, errors } = await createLabel({
        variables: {
          carrier: CARRIER,
          senderReference1: `${SENDER_REFERENCE_1_PREFIX} ${accountData?.workOrderNumber}`,
          senderReference2: SENDER_REFERENCE_2,
          accountNumber: ACCOUNT_NUMBER,
          // pickupAddress: {
          //   ...PICKUP_ADDRESS,
          //   dpid: addressData?.getAddressDetails?.dpid
          //     ? parseFloat(addressData?.getAddressDetails?.dpid)
          //     : 0,
          // },
          addressId: selectedAddressId.toString(),
          fromAddress,
          toAddress,
          tokenNumber: accountData?.elabelTokenNumber,
          senderDetails: {
            name:
              !isEmpty(accountData?.customerName) &&
              accountData?.customerName != 'null'
                ? accountData?.customerName
                : '',
            phone:
              !isEmpty(accountData?.phoneNumber) &&
              accountData?.phoneNumber != 'null'
                ? accountData?.phoneNumber
                : '',
            email:
              !isEmpty(accountData?.emailId) && accountData?.emailId != 'null'
                ? accountData?.emailId
                : '',
            site_code: SITE_CODE,
          },
          receiverDetails: {
            ...(postCode! < 4400
              ? RECEIVER_DETAILS_AUCKLAND
              : RECEIVER_DETAILS_WELLINGTON),
          },
          deliveryAddress: {
            ...DELIVERY_ADDRESS,
            dpid:
              postCode! < 4400
                ? RECEIVER_DETAILS_AUCKLAND_DPID
                : RECEIVER_DETAILS_WELLINGTON_DPID,
          },
          parcelDetails: parsal_details,
        },
      });

      // Ensure the response contains the consignment_id
      const { consignment_id, tracking_id, consignment_status } =
        data.createLabel;
      if (!consignment_id || !tracking_id) {
        setConfirmAddressLoading(false);
        setErrorMsg(
          'Incorrect information. Check your details and try again',
        );
        segment.track(`submit_clicked`, {
          alertMessage: "Incorrect information. Check your details and try again " + consignment_status,
          accountVerification: accountVerification,
          account_id: accountData?.accountNumber,
          order_id: accountData.workOrderNumber,
          email: accountData.emailId,
          linkText: module?.idCheckCtaName || 'Proceed',
          linkPosition: 'content',
          linkUrl: `/returnbagprocess/returnsuccess?labelId=${consignment_id}`,
          trackingID: tracking_id,
          returnCentre: toAddress,
        });
      } else {
        sessionStorage.setItem(
          'addressDetails',
          JSON.stringify({
            toAddress,
            fromAddress,
            addressId: addressObj?.id,
            trackingID: tracking_id,
          }),
        );
        segment.track(`submit_clicked`, {
          alertMessage: "Success " + consignment_status,
          accountVerification: accountVerification,
          account_id: accountData?.accountNumber,
          order_id: accountData.workOrderNumber,
          email: accountData.emailId,
          linkText: module?.idCheckCtaName || 'Proceed',
          linkPosition: 'content',
          linkUrl: `/returnbagprocess/returnsuccess?labelId=${consignment_id}`,
          trackingID: tracking_id,
          returnCentre: toAddress,
        });
        setConfirmAddressLoading(false);

        navigate(`/returnbagprocess/returnsuccess?labelId=${consignment_id}`, {
          state: {
            toAddress: toAddress,
            fromAddress: fromAddress,
            accountVerification: accountVerification,
            accountData: accountData,
            trackingID: tracking_id,
          },
        });
      }
    } catch (err: any) {
      setConfirmAddressLoading(false);
      segment.track(`submit_clicked`, {
        accountVerification: accountVerification,
        alertMessage:'Incorrect information. Check your details and try again',
        account_id: accountData?.accountNumber,
        order_id: accountData.workOrderNumber,
        email: accountData.emailId,
        linkText: module?.idCheckCtaName || 'Proceed',
        linkPosition: 'content',
        linkUrl: `/returnbagprocess/returnsuccess?labelId=`,
        trackingID: '',
        returnCentre: toAddress,
      });
      setErrorMsg(
        'Incorrect information. Check your details and try again',
      );
    }
  };
  const onConfirm = async () => {
     setConfirmAddressLoading(true);
     await handleCreateLabel();
  }

  return (
    <div className="p-12 md:container m-auto md:pb-10 lg:px-20 pt-10 mt-4 text-navy">
      {/* Header */}
      <h4 className="sky-h2-black mb-2">
        Kia ora <span>{accountData?.customerName}</span>
      </h4>
      <p className="mt-2 text-2xl font-bold md:text-16px">
        Account Number: {accountData?.accountNumber}
      </p>
      <p className="py-2 text-16px">
        {documentToReactComponents(module?.secondPageDescription, options)}
      </p>

      {/* Address Confirmation Section */}
      <div className="p-4 px-0">
        <h2
          className="text-2xl font-bold text-gray-900"
          style={{ fontFamily: 'MarkPro-Black SKY' }}
        >
          {module?.secondPageTitle}
        </h2>
        <p className="text-gray-600 text-16px mt-1">
          {documentToReactComponents(module?.secondPageSubTitle, options)}
        </p>

        {/* Address Input */}
        <div className="block  mt-4 font-bold text-2xl md:text-16px">
          {module?.secondPageTextTitle ?? "Your address"}
        </div>
        <div className='relative w-full md:w-1/2 '>
          {address?<div style={{right:'10px'}} className='absolute  top-5'>
        <ElableGreenTick />
        </div>:""}
        <input
          id="address"
          type="text"
          placeholder="Enter your address"
          className="w-full pr-16  mt-2 p-3 border rounded-lg focus:ring focus:ring-blue-300 outline-none"
          value={address}
          onChange={(e) => {
            setAddress(e?.target?.value);
            if (e?.target?.value?.trim().length === 0) {
              setToAddress('');
              setIsConfirm(true);
            }
            setShowDropdown(true);
          }}
        />
       </div>
        {/* Address Suggestions Dropdown */}
        {showDropdown && data?.searchAddress.length > 0 && (
          <ul className="absolute z-10 lg:w-5/12 w-4/5 bg-white border border-gray-300 rounded-lg mt-1 shadow-lg max-h-48 overflow-auto ">
            {data.searchAddress.map((addr: any) => (
              <li
                key={addr.id}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleSelectAddress(addr.address, addr)}
              >
                {addr.address}
              </li>
            ))}
          </ul>
        )}

        {/* Loading & Error Messages */}
        {loading && <p className="text-gray-500 mt-2">Loading...</p>}
        {error && <p className="text-red-500 mt-2">Error fetching addresses</p>}
      </div>

      {/* {toAddress && (
        <>
          <p
            className="mt-2 text-2xl font-bold md:text-16px"
            style={{ fontFamily: 'MarkPro-Bold SKY' }}
          >
            {module?.secondPageReturnTextTitle ?? 'Sky Return Centre'}
          </p>
          <p className="">{toAddress}</p>
        </>
      )} */}

      {errorMsg && <p className="text-red-500 mt-2">{errorMsg}</p>}

      {/* Confirm Button */}
      <button
        className={`w-full md:w-1/4 mt-4 py-2 rounded-full font-semibold transition ${
          confirmAddressLoading || isConfirm
            ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
            : 'bg-blue-600 hover:bg-blue-700 text-white'
        }`}
        onClick={() => onConfirm?.()}
        disabled={confirmAddressLoading || isConfirm}
      >
        {confirmAddressLoading ? 'Validating...' : module?.secondPageCtaName ?? 'Confirm'}
      </button>

      {/* Steps Section */}
      {module?.returnBagSteps?.fields?.galleryContent &&
        module?.returnBagSteps?.fields.galleryContent.length > 0 && (
          <>
            <h2 className="mt-10 text-2xl font-bold md:text-16px">
              {documentToReactComponents(module?.returnBagStepTitle, options)}
            </h2>
            <StepsComponent
              galleryContent={module?.returnBagSteps?.fields.galleryContent}
            />
          </>
        )}
    </div>
  );
};

export default ReturnBagConfirmAddress;

