import { agentContentfulClient } from "./services";
import nzPostData from "./components/nzpost/nzPost.json";

export const sendAnalytics = async (
    event_name: string,
    extraParam: any,
  ): Promise<void> => {
    const commonParams = {
      title: document.title,            // Page title
      url: window.location.href,        // Full URL of the page
      path: window.location.pathname,   // Path (e.g., /home, /about)
      referrer: document.referrer,      // Referrer (if available)
    };
    const segmentSent = new Promise<void>((resolve, reject) => {
      window.analytics?.track(
        event_name,
        { ...commonParams, ...extraParam },
      );
      resolve();
    });
  
    return segmentSent.then(() => {});
  };
  

  export const logImpression = (impressionData: any): void => {
    // if (impressionData.impression.feature === SKYWEB_HOME_BUNDLE_LINK_TEXT) {
    //   if (window.gtag) {
    //     window.gtag('event', 'split_impression', {
    //       'split_name': impressionData.impression.feature,
    //       'split_treatment': impressionData.impression.treatment,
    //       'split_label': impressionData.impression.label,
    //       'split_changeno': impressionData.impression.changeNumber,
    //       'split_time': impressionData.impression.time
    //       // can add additional properties here as well
    //     });
    //   }
    // }
  };
  

  export const queryContentful = async (pathname: string): Promise<any> => {
    const paramsPath = location.pathname.includes('/returnbagprocess') ? '/returnbagprocess' :`${pathname}`;
    const entryItems = await agentContentfulClient.getEntries({
      content_type: 'home',
      'fields.slug': paramsPath.replace(/\/undefined/g, ''),
      limit: 1,
      include: 10,
    });
  
    if (entryItems.items[0]?.fields) {
      return entryItems.items[0].fields;
    }
    return { status: false };
};


export const getParcelsByFilter = async (deviceCount: number) => {
  if (deviceCount >= 5) { // deviceCount 5 or more
    deviceCount = 3;
  } else if (deviceCount >= 3) { // deviceCount 3 / 4
    deviceCount = 2;
  } else { // deviceCount 0, 1, 2
    deviceCount = 1;
  }

  const filteredParcels = nzPostData.parcel_details.filter(parcel => 
    parcel.deviceQuantity === deviceCount
  );

  const parcel = filteredParcels.length > 0 ? filteredParcels[0] : nzPostData.parcel_details[0];

  // Create a shallow copy of the parcel object
  const { deviceQuantity, ...parcelWithoutFilterName } = parcel;

  return parcelWithoutFilterName;
}
