import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GenericProductCard } from '@sky-tv-group/components/dist/components/GenericProductCard';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Slider from 'react-slick';

interface LargeGalleryProps {
  module: any
  sendProduct: any
  sendStandard: any
}

const LargeGallery: React.FC<LargeGalleryProps> = ({ module, sendProduct, sendStandard}) => {
  const { width, height } = useWindowDimensions();

  const [slideText, setSlideText] = React.useState(
    module.galleryContent[0].fields.sectionCallToAction,
  ) as any;
  const [slideLink, setSlideLink] = React.useState(
    module.galleryContent[0].fields.sectionCallToActionLink,
  ) as any;
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [copyright, setCopyright] = React.useState();
  const changeContent = (e: any, index: number, links: any, text: any) => {
    const backgroundElements = document.querySelectorAll('.main-content');
    backgroundElements.forEach((item: any, set: number) => {
      if (set === index) {
        item.classList.add('show');
      } else {
        item.classList.remove('show');
      }
    });

    setSlideText(text);
    setSlideLink(links);
    setSlideIndex(index);

    const copyRight = documentToReactComponents(
      module.galleryContent[index].fields.copyrightText,
    ) as any;

    setCopyright(copyRight);

    const infoTextElements = document.querySelectorAll('.info-text');
    infoTextElements.forEach((item: any) => {
      item.classList.remove('show');
    });

    const currentHighlightElements =
      document.querySelectorAll('.current-highlight');
    currentHighlightElements.forEach((item: any) => {
      item.classList.remove('show');
    });

    e.target.parentNode.childNodes[1].childNodes[0].childNodes[0].classList.add(
      'show',
    );
    e.target.parentNode.childNodes[1].childNodes[1].classList.add('show');
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="large-gallery relative">
      <div className="max-w-[2000px] mx-auto bg-black relative min-h-[536px]">
        {width > 1024 ? (
          module.galleryContent.map((item: any, index: number) => {
            const cardData = item.fields.productCard.fields;

            return (
              <div
                className={
                  index === 0
                    ? 'main-content show pl-[30px] py-[40px] absolute w-full'
                    : 'main-content pl-[30px] py-[40px] absolute w-full'
                }
                key={index}
              >
                <div className="absolute z-[10] top-0 left-[25%] bg-gradient-to-r from-black to-transparent h-full w-[15%]"></div>
                <img
                  style={{ zIndex: index }}
                  className="absolute w-full h-full top-0 lg:h-full lg:right-0 lg:max-w-[75%]"
                  src={item.fields.largeBackgroundImage.fields.file.url}
                  alt={item.fields.largeBackgroundImage.fields.file.text}
                />
                <div className="max-w-[1310px] mx-auto">
                  {item.fields.promotionText ? (
                    <div className="relative mb-[10px] z-[10] bg-[url('/public/pink-orange-feature.svg')] bg-right text-white text-[21px] px-[7px] py-[2px] pb-[0px] pr-[20px] rounded-[5px] inline-block">
                      {item.fields.promotionText}
                    </div>
                  ) : null}
                  <div className="relative z-[10] mt-[0px] mb-[10px] text-[24px] lg:text-[30px] text-white font-sky-book">
                    {documentToReactComponents(item.fields.contentsection)}
                  </div>
                  <div className="max-w-[343px] relative z-[10]">
                    
                    <GenericProductCard
                      kkService={null as any}
                      secondaryProductCardCTA={()=>sendProduct("Product Added", "acquisition", "DTH", cardData.title, cardData.title, cardData.title, cardData.price, 1, cardData.sku, cardData.type[0])}
                      compactMode
                      card={{
                        bgColor: cardData.bgColor
                          ? cardData.bgColor
                          : '#000000',
                        billingFrequency: cardData.priceFrequencyType,
                        imageSrc: {
                          text: cardData.thumbnail?.fields?.file?.text ?? '',
                          url: cardData.thumbnail?.fields?.file?.url ?? '',
                        },
                        // lozengeText: cardData.lozengeText,
                        sku: cardData.sku,
                        subtitle: cardData.description,
                        discountedPrice: cardData.price,
                        title: cardData.title,
                        type: cardData.type,
                        coupon: null,
                        termsAndConditions: null,
                        descriptionList: cardData.detailsList,
                        copyrightText: cardData.copyrightText,
                        iconList: cardData.iconList?.map((icon: any) => ({
                          url: icon?.fields?.file?.url ?? '',
                          text: icon?.fields?.file?.text ?? '',
                        })),
                      }}
                    />
                  </div>
                </div>
                <div className="absolute z-[5] bottom-0 left-0 w-full bg-gradient-to-t from-black h-[340px] max-w-[2000px]"></div>
                <p className="text-white z-[11] absolute bottom-[10px] right-[20px] text-[12px] opacity-[0.5]">
                  {copyright}
                </p>
              </div>
            );
          })
        ) : (
          <Slider {...settings as any}>
            {module.galleryContent.map((item: any, index: number) => {
              // console.log(item)
              const cardData = item.fields.productCard.fields;
              return (
                <div
                  className="p-[30px] inline-block w-full relative"
                  key={index}
                >
                  <div className="absolute top-[140px] max-w-[314px] w-full">
                  <div className="relative">
                  <div className="absolute z-[9] top-0 left-0 w-full bg-gradient-to-b from-black h-[20px] max-w-[2000px]"></div>
                  <div className="absolute z-[9] top-0 left-0 bg-gradient-to-r from-black to-transparent w-[20px] h-full"></div>
                  <div className="absolute z-[9] top-0 right-0 bg-gradient-to-l from-black to-transparent w-[20px] h-full"></div>
                  <img
                    className="h-[230px] w-full object-cover max-w-[314px]"
                    src={item.fields.largeBackgroundImage.fields.file.url}
                    alt={item.fields.largeBackgroundImage.fields.file.text}
                  />
                  <div className="absolute z-[9] bottom-0 left-0 w-full bg-gradient-to-t from-black h-[20px] max-w-[2000px]"></div>
                  </div>
                  </div>
                  <div className="max-w-[1310px] mx-auto">
                    {item.fields.promotionText && index === 0 ? (
                      <div className="relative mb-[20px] z-[10] bg-[url('/public/pink-orange-feature.svg')] bg-right text-white text-[21px] px-[7px] py-[2px] pb-[0px] pr-[20px] rounded-[5px] inline-block">
                        {item.fields.promotionText}
                      </div>
                    ) : (
                      <div className="relative mb-[20px] z-[10] px-[7px] py-[2px] pb-[0px] pr-[20px] inline-block h-[30px]"></div>
                    )}
                    <div className="relative z-[10] mt-[0px] mb-[50px] text-[20px] lg:text-[44px] text-white font-sky-book">
                      {documentToReactComponents(item.fields.contentsection)}
                    </div>
                    <div className="max-w-[344px] relative z-[10] top-[200px]">
                      <div className="h-[160px] max-h-[160px] p-[20px] pr-[0] overflow-hidden">
                        <h5 className="text-[14px] text-white">
                          {item.fields.contentTitle}
                        </h5>
                        <p className="text-[12px] leading-[14px] mb-[10px] text-white">
                          {item.fields.contentAvailableDate}
                        </p>
                        <div className="text-[10px] leading-[15px] text-white">
                          {documentToReactComponents(
                            item.fields.contentDescription,
                          )}
                        </div>
                      </div>
                      <GenericProductCard
                        kkService={null as any}
                        secondaryProductCardCTA={()=>sendProduct("Product Added", "acquisition", "DTH", cardData.title, cardData.title, cardData.price, 1, cardData.sku, cardData.type[0])}
                        compactMode
                        card={{
                          bgColor: cardData.bgColor
                            ? cardData.bgColor
                            : '#000000',
                          billingFrequency: cardData.priceFrequencyType,
                          imageSrc: {
                            text: cardData.thumbnail?.fields?.file?.text ?? '',
                            url: cardData.thumbnail?.fields?.file?.url ?? '',
                          },
                          // lozengeText: cardData.lozengeText,
                          sku: cardData.sku,
                          subtitle: cardData.description,
                          discountedPrice: cardData.price,
                          title: cardData.title,
                          type: cardData.type,
                          coupon: null,
                          termsAndConditions: null,
                          descriptionList: cardData.detailsList,
                          copyrightText: cardData.copyrightText,
                          iconList: cardData.iconList?.map((icon: any) => ({
                            url: icon?.fields?.file?.url ?? '',
                            text: icon?.fields?.file?.text ?? '',
                          })),
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-white z-[11] absolute bottom-[10px] right-[20px] text-[12px] opacity-[0.5]">
                    {copyright}
                  </p>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
      <div className="hidden lg:block carousel-panel px-[30px] pt-[20px] pb-[33px] bg-black bottom-0 w-full max-w-[2000px] mx-auto">
        <div className="mx-auto max-w-[1280px] relative z-[11]">
          {module.galleryContent.map((item: any, index: number) => {
            return (
              <div
                className="cursor-pointer inline-block align-top mr-[10px] w-[290px] relative z-[2] pinch-panel"
                key={index}
              >
                <div
                  className="click-area absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.0001)] z-10 hover:bg-[rgba(0,0,0,0.2)] cursor-pointer"
                  onClick={(e: any) => {
                    e.preventDefault();
                    changeContent(
                      e,
                      index,
                      module.galleryContent[index].fields.sectionCallToActionLink,
                      module.galleryContent[index].fields.sectionCallToAction,
                    )
                    sendStandard(false, "Cta Clicked ",  item.fields.sectionCallToAction.content[0].content[0].value+ item.fields.sectionCallToAction.content[0].content[1].value, item.fields.sectionCallToActionLink)
                  }
                  }
                ></div>
                <div className="info-panel ">
                  <div
                    className="bg-no-repeat bg-cover h-[165px] rounded-[8px]"
                    style={{
                      backgroundImage:
                        "url('" +
                        item.fields.contentThumbnail.fields.file.url +
                        "')",
                    }}
                    key={index}
                  >
                    <div
                      className={
                        index === 0
                          ? 'show info-text bg-white rounded-[4px] h-full px-[20px] py-[12px]'
                          : 'info-text bg-white rounded-[4px] h-full px-[20px] py-[12px]'
                      }
                    >
                      <h5 className="text-[14px]">
                        {item.fields.contentTitle}
                      </h5>
                      <p className="text-[12px] leading-[14px] mb-[10px]">
                        {item.fields.contentAvailableDate}
                      </p>
                      <div className="text-[10px] leading-[15px]">
                        {documentToReactComponents(
                          item.fields.contentDescription,
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      index === 0
                        ? 'current-highlight show bg-sky-pink h-[4px] w-full mt-[12px]'
                        : 'current-highlight bg-sky-pink h-[4px] w-full mt-[12px]'
                    }
                  ></div>
                </div>
              </div>
            );
          })}
          <div className="absolute top-[35%] h-full -right-[10px] z-10">
            <div className="text-[18px] text-white flex justify-items-start relative">
              <div onClick={(e)=>{e.preventDefault(); sendStandard(true, "Cta Clicked ", slideText.content[0].content[0].value+slideText.content[0].content[1].value, slideLink)}}>
                <div className="w-full relative z-[5] pr-[60px] hover:opacity-60 cursor-pointer bg-right bg-no-repeat h-[60px] pt-[18px]" style={{backgroundImage: `url('/next-icon.svg')`}}>
                  {documentToReactComponents(slideText)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LargeGallery;
