import React, { useState, useEffect } from 'react';
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { ReturnBagLanding } from './ReturnBagLanding';
import ConfirmAddress from './ReturnBagConfirmAddress';
import ReturnBagFinal from './ReturnBagFinal';
import { useLazyQuery } from '@apollo/client';
import { VALIDATE_ACCOUNT } from '../../graphql/queries/validateAccount';

interface PageProps {
  module: any;
  validateAccountData?: any;
}
interface AccountData {
  accountNumber: string;
  houseNumber: string;
  workOrderNumber: string;
  fromAddress: string | null;
  consignmentId: string | null;
  toAddress: string | null;
  trackingID: string | null;
  trackingIDUpdatedOn: string | null;
  elabelTokenGeneratedOn: string;
  elableReferenceNumber: string | null;
  elabelTokenNumber: string;
  elableReferenceNumberUpdatedOn: string | null;
}

const ELabelLayout = ({ module }: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [accountData, setAccountData] = useState<AccountData | null>(null);
  const navigate = useNavigate();

  const [
    validateAccount,
    { data: validatedData, loading: accountLoading, error: accountError },
  ] = useLazyQuery(VALIDATE_ACCOUNT);
  const pathname = location.pathname;

  const token = searchParams.get('token');

  const validateAccountData = async () => {

    try{
      const accountData:any = await validateAccount({
        variables: { token },
      });
      setAccountData(accountData?.data?.getAccount as AccountData);

    }catch(err:any){
      throw new Error(err)
    }

  
  }

  useEffect(() => {
    // Extract token from URL
    if (accountError) navigate('/returnbagprocess')
    if (token) {
      
      validateAccount({
        variables: { token },
      });
    
    }

    if (validatedData) {
      setAccountData(validatedData?.getAccount);
    }
  }, [token,location.search,validatedData]);



  if (accountLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
      </div>
    );
  }
  let content;

  const isReturnSuccess = (pathname.includes('returnsuccess') || (accountData && accountData?.consignmentId !== 'null') ? true : false);
  const isConfirmation = (pathname.includes('confirmation') || (searchParams.has('token') && accountData && accountData?.consignmentId === "null"))? true: false;

  switch (true) {
    case isReturnSuccess:
      content = <ReturnBagFinal module={module} validateAccountData={accountData} />;
      break;
    case isConfirmation:
      content = <ConfirmAddress module={module} validateAccountData={accountData} />;
      break;
    default:
      if (location.pathname !== '/returnbagprocess') window.location.replace('/returnbagprocess')
      content = <ReturnBagLanding module={module} />;
      break;
  }


  return <>{content}</>;



};

export { ELabelLayout };