import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { VALIDATE_ACCOUNT } from '../../graphql/queries/validateAccount';
import { useLazyQuery, useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../content-modules/satellite-conent/Info';
import { segment, validateEmail } from '@sky-tv-group/shared';
import { isEmpty } from 'lodash';

interface PageProps {
  module: any;
}
enum SelectedOption {
  EMAIl,
  ACCOUNT_NUMBER,
}
// Function to get the string representation from the enum
function getEnumKey(option: SelectedOption): string {
  return SelectedOption[option]; // Convert numeric value back to string key
}
const ReturnBagLanding: React.FC<PageProps> = ({ module }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [selectedOption, setSelectedOption] = useState<SelectedOption>(
    SelectedOption.EMAIl,
  );

  const [
    validateAccount,
    { data: accountData, loading: accountLoading, error: accountError },
  ] = useLazyQuery(VALIDATE_ACCOUNT);

  useEffect(() => {
    const accountSessionData =
      sessionStorage.getItem('accountDetails') &&
      JSON.parse(sessionStorage.getItem('accountDetails')!);
    if (accountSessionData?.accountValue) {
      setSelectedOption(
        accountSessionData?.selectedOption == '0'
          ? SelectedOption.EMAIl
          : SelectedOption.ACCOUNT_NUMBER,
      );
      setInputValue(accountSessionData?.accountValue || '');
    }
  }, []);

  const isValidate = (): string | null => {
    if (!inputValue?.toString().trim()) {
      return 'Please enter at least one: Account Number, or Email.';
    }

    // Validate email only if selected
    if (
      selectedOption === SelectedOption.EMAIl &&
      validateEmail(inputValue).length > 0
    ) {
      return validateEmail(inputValue).toString();
    }
    if (
      selectedOption === SelectedOption.ACCOUNT_NUMBER &&
      !/^\d{0,9}$/.test(inputValue)
    ) {
      return 'Please enter valid account number';
    }

    return null; // No errors
  };

  const proceed = async () => {
    const msg = isValidate(); // Get validation message
    if (msg) {
      setIsValid(false);
      setErrorMsg(msg);
      segment.track(`submit_clicked`, {
        alertMessage: msg,
        account_id:
          selectedOption == SelectedOption.ACCOUNT_NUMBER ? inputValue : null,
        accountVerification: getEnumKey(selectedOption),
        order_id: null,
        email: selectedOption == SelectedOption.EMAIl ? inputValue : null,
        linkText: module?.idCheckCtaName || 'Proceed',
        linkPosition: 'content',
        linkUrl: '/returnbagprocess/confirmation',
      });
      return; // Stop further execution
    }

    try {
      const { data, error } = await validateAccount({
        variables: {
          accountNo:
            selectedOption == SelectedOption.ACCOUNT_NUMBER
              ? parseFloat(inputValue)
              : null,
          email: selectedOption == SelectedOption.EMAIl ? inputValue : '',
          orderNo: '',
        },
      });
      if (
        data?.getAccount != null &&
        data?.getAccount?.consignmentId != 'null' &&
        !isEmpty(data?.getAccount?.consignmentId) &&
        data?.getAccount?.trackingID != 'null'
      ) {
        sessionStorage.removeItem('addressDetails');
        sessionStorage.setItem(
          'accountDetails',
          JSON.stringify({
            accountValue: inputValue.toString(),
            selectedOption: selectedOption.toString(),
          }),
        );

        segment.track(`submit_clicked`, {
          alertMessage: 'Success',
          account_id:
            selectedOption == SelectedOption.ACCOUNT_NUMBER ? inputValue : null,
          accountVerification: getEnumKey(selectedOption),
          order_id: null,
          email: selectedOption == SelectedOption.EMAIl ? inputValue : null,
          linkText: module?.idCheckCtaName || 'Proceed',
          linkPosition: 'content',
          linkUrl: '/returnbagprocess/confirmation',
        });

        navigate(
          `/returnbagprocess/returnsuccess?labelId=${data?.getAccount?.consignmentId}`,
          {
            state: {
              toAddress: data?.getAccount?.toAddress,
              fromAddress: data?.getAccount?.fromAddress,
              accountVerification: getEnumKey(selectedOption),
              accountData: data?.getAccount,
              trackingID: data?.getAccount?.trackingID,
            },
          },
        );
      } else if (data?.getAccount != null) {
        sessionStorage.removeItem('addressDetails');
        sessionStorage.setItem(
          'accountDetails',
          JSON.stringify({
            accountValue: inputValue.toString(),
            selectedOption: selectedOption.toString(),
          }),
        );
        segment.track(`submit_clicked`, {
          alertMessage: 'Success',
          account_id:
            selectedOption == SelectedOption.ACCOUNT_NUMBER ? inputValue : null,
          accountVerification: getEnumKey(selectedOption),
          order_id: null,
          email: selectedOption == SelectedOption.EMAIl ? inputValue : null,
          linkText: module?.idCheckCtaName || 'Proceed',
          linkPosition: 'content',
          linkUrl: '/returnbagprocess/confirmation',
        });

        navigate('/returnbagprocess/confirmation', {
          state: {
            accountData: data?.getAccount,
            accountVerification: getEnumKey(selectedOption),
          },
        });
      } else {
        setErrorMsg(
          `Your details doesn't match our records. Please check your details and try again`,
        );

        segment.track(`submit_clicked`, {
          alertMessage: `Your details doesn't match our records. Please check your details and try again`,
          account_id:
            selectedOption == SelectedOption.ACCOUNT_NUMBER ? inputValue : null,
          accountVerification: getEnumKey(selectedOption),
          order_id: null,
          email: selectedOption == SelectedOption.EMAIl ? inputValue : null,
          linkText: module?.idCheckCtaName || 'Proceed',
          linkPosition: 'content',
          linkUrl: '/returnbagprocess/confirmation',
        });
      }
    } catch (err) {
      setErrorMsg('Incorrect information. Check your details and try again');

      segment.track(`submit_clicked`, {
        alertMessage: 'Incorrect information. Check your details and try again',
        account_id:
          selectedOption == SelectedOption.ACCOUNT_NUMBER ? inputValue : null,
        accountVerification: getEnumKey(selectedOption),
        order_id: null,
        email: selectedOption == SelectedOption.EMAIl ? inputValue : null,
        linkText: module?.idCheckCtaName || 'Proceed',
        linkPosition: 'content',
        linkUrl: '/returnbagprocess/confirmation',
      });
    }
  };
  const resetValues = (e: any) => {
    setInputValue(e.target.value);
  };
  return (
    <>
      <div className="p-12 md:container m-auto md:pb-10 lg:px-20 pt-10 mt-4">
        <div>
          <h4 className="sky-h1-black mb-2">{module?.idCheckTitle}</h4>
          <p className="text-16px  markPro-Black SKY md:text-20px">
            {documentToReactComponents(module?.idCheckDescription, options)}
          </p>
        </div>

        <div className="mt-6">
          {module?.idCheckTextTitle && (
            <div className="sky-h4-black mb-2 text-midnight">
              {module?.idCheckTextTitle}
            </div>
          )}

          {module?.idCheckTextSubTitle && (
            <span className="text-16px md:text-20px  mb-1">
              {module?.idCheckTextSubTitle}
            </span>
          )}

          <div className="flex flex-col text-16px md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-6 mt-4">
            {[
              { label: 'Email address', value: SelectedOption.EMAIl },
              { label: 'Account number', value: SelectedOption.ACCOUNT_NUMBER },
            ].map((option) => (
              <label
                key={option.value}
                className="flex items-center cursor-pointer"
              >
                <input
                  type="radio"
                  name="verification"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={() => {
                    setErrorMsg('');
                    setInputValue('');
                    setIsValid(true);
                    setSelectedOption(option.value);
                  }}
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 flex items-center justify-center rounded-full border-2 ${
                    selectedOption === option.value
                      ? 'border-green-500'
                      : 'border-gray-400'
                  }`}
                >
                  {selectedOption === option.value && (
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                  )}
                </div>
                <span className="ml-2 text-midnight font-medium">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
          <input
            id="address"
            type="text"
            style={{ borderColor: isValid ? 'grey' : 'red' }}
            placeholder="Insert Here"
            maxLength={selectedOption == SelectedOption.ACCOUNT_NUMBER ? 9 : 80}
            className="w-full md:w-2/4 mt-5 p-3 border rounded-lg focus:ring focus:ring-blue-300 outline-none"
            value={inputValue}
            onChange={(e) => {
              resetValues(e);
            }}
          />
        </div>
        {errorMsg && (
          <p className="mt-2" style={{ color: 'red' }}>
            {errorMsg}
          </p>
        )}
        <button
          className={`w-full md:w-1/4 mt-4 py-2 rounded-full font-semibold transition ${
            !inputValue?.toString()?.trim() || accountLoading
              ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
          onClick={proceed}
          disabled={!inputValue?.toString().trim() || accountLoading}
        >
          {accountLoading
            ? 'Validating...'
            : module?.idCheckCtaName || 'Proceed'}
        </button>
      </div>
    </>
  );
};

export { ReturnBagLanding };
