import  { Helmet } from "react-helmet";

const HeadSection = ( data: any ) => {

    return  (
        <Helmet>
            <title>{data.data.title}</title>
            <meta property="og:title" content={data.data.title}/>
            <meta name="description" content={data.data.description}/>
            <meta name="description" content={data.data.description} lang="en-GB" />
            <meta property="og:description" content={data.data.description}/>
            <link rel="canonical" href={"https://www.sky.co.nz"+data.data.urlSlug}/>
            <meta property="og:url" content={"https://www.sky.co.nz"+data.data.urlSlug}/>
        </Helmet>
    )
};

export default HeadSection;
