import { gql } from '@apollo/client';

export const VALIDATE_ACCOUNT = gql`
  query GetAccount($accountNo: Float, $email: String, $orderNo: String, $token: String) {
    getAccount(accountNo: $accountNo, email: $email, orderNo: $orderNo, token: $token) {
      accountNumber
      houseNumber
      workOrderNumber
      consignmentId
      fromAddress
      toAddress
      trackingID
      trackingIDUpdatedOn
      elabelTokenGeneratedOn
      elableReferenceNumber
      elabelTokenNumber
      elableReferenceNumberUpdatedOn
      customerName
      phoneNumber
      emailId
      siteCode
      returnBoxesCount
    }
  }
`;
