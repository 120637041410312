import React from 'react';
import {
  formatMoneyAutoDecimal,
  NSB_SKY_REWARDS_LOZENGE_TEXT,
  SPLITIO_KEY,
  TANDC_URL,
  useSplitIO,
} from '@sky-tv-group/shared';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Coupon, ImageDetails } from '@sky-tv-group/graphql';
import { ReactComponent as BlackTick } from '../assets/black-tick.svg';

export interface HomePagePromotionaCardDetails {
  bgColor?: string | null;
  billingFrequency?: string | null;
  campaign?: string | null;
  coupon?: Coupon | null;
  disclaimer?: string | null;
  discountText?: string | null;
  discountedPrice: number | null;
  fromPrice?: string | null;
  imageSrc?: ImageDetails | null;
  lozengeText?: string | null;
  sku?: string | null;
  occurrenceType?: string | null;
  subtitle?: string | null;
  termsAndConditions?: any | null;
  title: string;
  type?: string | null;
  copyrightText?: string | null;
  descriptionList?: string[] | null;
  iconList?: ImageDetails[] | null;
  addLinkText?: string | null;
  addLink?: string | null;
  detailsText?: string | null;
  detailsLink?: string | null;
  promotionImage?: ImageDetails | null;  
}

export interface HomePagePromotionalCardProps {
  card: HomePagePromotionaCardDetails;
  buttons?: React.ReactNode;
  bgColor?: string;
  fixedWidth?: number;
  showBottomGradient?: boolean;
  cardType?: 'PRODUCT' | 'OFFER';
  compactMode?: boolean;
}

function wrapDecimalInSpan(num: number): string {
  const [whole, decimal] = num.toFixed(2).split('.');
  return `${whole}<span class="text-lg" style="line-height: 0; margin-top: 19px;">.${decimal}</span>`;
}

export function HomePagePromotionalCard({
  card,
  buttons,
  bgColor,
  compactMode,
  fixedWidth,
  cardType = 'OFFER',
}: HomePagePromotionalCardProps) {
  const [showCompetitionFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_SHOW_PROMO_COMPETITION);
  const showCompetition = showCompetitionFlag && card.lozengeText === NSB_SKY_REWARDS_LOZENGE_TEXT;
  return (
    <div
      className="relative flex flex-col generic-promotional-card w-full relative"
      style={{
        top: card.lozengeText ? "-40px" : "",
        minHeight: compactMode ? 300 : 800,
        width: fixedWidth ?? undefined,
        border: card.lozengeText ? "3px solid transparent" : "3px solid #A6A6A6",
        borderTop: card.lozengeText ? "0px" : "3px",
        borderTopColor: card.lozengeText ? "transparent" : "#A6A6A6",
        borderTopStyle: "solid",
        borderRadius: card.lozengeText ? "0px 0px 8px 8px" : "8px 8px 8px 8px",
        background: card.lozengeText ?
          "linear-gradient(#ffffff, #ffffff) padding-box,linear-gradient(63deg, #0057ff, #f94cb1, #ff9e1c ) border-box" : undefined,
      }} id="slickId">
      {/* Rounded Image as Absolute Element */}
      {card?.promotionImage?.url ? (
              <img
              src={card?.promotionImage?.url}
              alt={card?.promotionImage?.text ?? ''}
              style={{
                position: "absolute",
                top: "-35px",
                right: "12px",
                width: "90",
                height: "90", 
                zIndex: 1,
              }}
            />
          ) : null}

      {card?.lozengeText ? (<div style={{ height: '3px' }}></div>) : null}
      {card?.imageSrc?.url ? (
        <img
          style={{ borderRadius: card?.lozengeText ? '0px' : '5px 5px 0px 0px' }}
          src={card?.imageSrc?.url}
          alt={card?.imageSrc?.text ?? ''}
          className={`w-full object-center ${cardType === 'PRODUCT' ? 'object-contain' : 'absolute left-0 top-0 h-full object-contain'
            }`}
        />
      ) : null}
      <div className="pt-6 pl-4 pr-4 pb-2 flex flex-col gap-4 z-10 ">
        {card?.occurrenceType === 'Data' && (
          <div className="flex items-center pt-3 text-black">
            <img className="mr-2 mb-2" src={'https://static.sky.co.nz/sky/icons/icon-color-wifi.svg'} alt="" />
            <span className="sky-h5-black mr-2"> UNLIMITED </span> fibre
          </div>
        )}

        <h5 style={{ lineHeight: '34px', minHeight: '105px' }} className="font-skyBlack text-black text-[26px] md:text-[28px]">
          {card?.title}
        </h5>
        <div className="text-black">
          {
            (card?.fromPrice !== card?.discountedPrice && card?.fromPrice) ?
              (<p className="line-through " style={{ fontSize: '14px', lineHeight: '18px' }}>
                {formatMoneyAutoDecimal(+card.fromPrice)}
              </p>) : <div style={{ height: '18px' }}></div>}
          {card?.discountedPrice !== null ? (
            <div>
              <div className="ml-4 pr-2 mr-8 relative inline-block">
                <span
                  className="font-skyBlack absolute "
                  style={{ fontSize: 20, lineHeight: '24px', right: '100%', top: 6 }}>
                  $
                </span>
                <span className="font-skyBlack" style={{ fontSize: 46, lineHeight: '56px' }}>
                  <span
                    className="flex flex-row items-start"
                    dangerouslySetInnerHTML={{
                      __html: wrapDecimalInSpan(+formatMoneyAutoDecimal(card.discountedPrice).substring(1)),
                    }}
                  />
                </span>
                {card?.billingFrequency === 'Monthly' && (
                  <span
                    className=" absolute  "
                    style={{ fontSize: 12, lineHeight: '16px', left: 'calc(100% - 33px)', bottom: 6 }}>
                    /mth
                  </span>
                )}
                {card?.billingFrequency === 'One Time Payment' && (
                  <span
                    className="absolute min-w-28"
                    style={{ fontSize: 12, lineHeight: '16px', left: 'calc(100% - 33px)', bottom: 6 }}>
                    /one-off charge
                  </span>
                )}
              </div>
            </div>
          ) : null}
          {card.discountText &&
            (<><div
              className="z-10 overflow-hidden "
              style={{
                borderRadius: '4px',
                transform: 'skew(-15deg)',
                transformOrigin: '0 0',
                marginTop: `0px`,
                marginLeft: `0px`,
              }}>
              <div
                className="font-skyBook text-white whitespace-pre-line"
                style={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  padding: '8px',
                  wordBreak: 'keep-all',
                  whiteSpace: 'nowrap',
                  background: '#9100D4',
                  borderRadius: '4px',
                  transform: 'skew(15deg)',
                  transformOrigin: '0 0',
                  perspective: '1px',
                }}>
                {card.discountText}
              </div>
            </div></>)}


          <p className="font-skyBook text-black whitespace-pre-line" style={{ fontSize: '14px', lineHeight: '18px', marginTop: '8px' }}>
            {card?.subtitle}
          </p>
          <div style={{ width: '100%', background: '#C5C5C5', height: '1px', marginTop: '20px', marginBottom: '20px' }}></div>
          {card?.descriptionList && card?.descriptionList?.length && (
            <ul className="flex-grow mt-sky-xs">
              {card?.descriptionList &&
                card?.descriptionList?.map((item: any, index: number) => {
                  return (
                    <li
                      style={{ paddingLeft: 20 }}
                      className={`relative text-left tick sky-h7 text-black text-[14px] mb-sky-xs ${(card?.descriptionList && card?.descriptionList?.length-1 === index) && 'font-bold'}`}
                      key={`${index}-${item}`}>
                      <span className="absolute left-0" style={{ top: 2, width: 14, height: 14 }}>
                        <BlackTick className="mr-sky-sm w-full" />
                      </span>

                      {item}
                    </li>
                  );
                })}
            </ul>
          )}

          <br></br>
          {card?.termsAndConditions ? (
            <p className="" style={{ fontSize: 10, lineHeight: '12px' }}>
              {documentToReactComponents(card?.termsAndConditions)}
            </p>
          ) : null}
          {card?.disclaimer ? (
            <p className="" style={{ fontSize: 6, lineHeight: '8px' }}>
              {card.disclaimer}
            </p>
          ) : null}
          {showCompetition && (
            <p className="" style={{ fontSize: 10, lineHeight: '12px' }}>
              Competition <a href={TANDC_URL}>T&Cs</a> apply.
            </p>
          )}
        </div>
      </div>

      <div style={{ height: "50px", width: '100%' }}>
      </div>

      {buttons ? <div className={`${compactMode ? 'mt-auto' : 'bottom-0'} absolute w-full`}>{buttons}</div> : null}
    </div>
  );
}
