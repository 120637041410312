import useSWR from 'swr'
import { Layout } from '../components'
import { Auth0Provider } from '../store/authStore/auth0';
import config from '../store/authStore/auth0.config';
import { useAuth0 } from '../store/authStore/authStore';
import { DOMAIN } from '../config';
import {NotificationManager} from '@sky-tv-group/components';
import { useLocation } from 'react-router-dom';
import { BACKEND_ENDPOINT } from '@sky-tv-group/shared';
import { SalesForceChat } from '@sky-tv-group/components';
import { queryContentful } from '../helper';


const Home = () => {
    const { pathname } = useLocation(); // Get the pathname
    const onRedirectCallback = () => {
      window.history.replaceState({}, document.title, '/');
    };

    const { data } = useSWR(pathname, queryContentful)

    function AuthenticatedApp() {
        const { loading } = useAuth0();
        return loading ? <Layout data={data}/> : <Layout data={data}/>;
    }

    if(data) {
        return (
          <>
          <NotificationManager notificationsEndpoint={BACKEND_ENDPOINT} domain={DOMAIN} />
              <Auth0Provider
                config={{
                  domain: config.domain,
                  client_id: config.clientId,
                  redirect_uri: window.location.origin,
                  audience: config.audience,
                }}
                onRedirectCallback={onRedirectCallback}>
                <AuthenticatedApp/>
                {data?.isChat && <SalesForceChat target={'cart'}></SalesForceChat>}
          </Auth0Provider>
        </>
        );
    }
    return null;
}

export default Home;