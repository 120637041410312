import React from 'react';
import VideoPlayer from './video-player';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../Info';

interface VideoSectionProps {
    module: any;
    sendBanner: any;
}

const VideoSection: React.FC<VideoSectionProps> = (module, sendBanner) => {
    return (
        <>
            <section>
                <div className="w-full bg-[#00013A] flex flex-col items-center justify-center px-0 py-20 gap-10 opacity-100">
                    <div className="container m-auto h-full w-full" >
                        {/*Heading Copntent*/}
                        <div className="text-center mb-8">
                            <h2 className="sky-h3 md:sky-h3-black sky-text-midnight py-4 px-4" style={{ color: 'white' }}>{module?.module?.heading}</h2>
                            <p className="text-base text-white-600 px-4" style={{ color: 'white' }}>
                                {documentToReactComponents(module?.module?.description, options)}
                            </p>

                        </div>
                        <div className="flex items-center justify-center h-auto w-auto">
                            <div className='w-[804px] aspect-[16/9]'>
                                {module?.module?.brightCoveId && <VideoPlayer videoContentData={module?.module} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VideoSection;
