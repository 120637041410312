import * as React from 'react';
import { CardPanel, LargeGallery, ScrollingBanner, SingleBanner, LargeCallToAction, BuildYourOwn } from './'
import { Footer } from '@sky-tv-group/components'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { SkyHomeBannerCarousel } from './content-modules/scrollingBanner/SkyHomeBannerCarousel';
import { Bannersection, Channel888, Information, VideoSection, FaqSection } from './content-modules/satellite-conent';
import { ELabelLayout } from './nzpost/ELabelLayout';

interface ContentProps {
    content: any;
    links: any;
    sendProduct: any;
    sendBanner: any;
    sendStandard: any;
}


const Content: React.FC<ContentProps> = ({ content, links, sendProduct, sendBanner, sendStandard }) => {
    const scrollRef = React.useRef() as any
    const prevScroll = React.useRef(0) as any;
    const { width, height } = useWindowDimensions();

    React.useEffect(() => {
        // const scrolling = document.getElementById("scrollingBanner") as any
        // const banners = scrolling?.querySelectorAll(".content-banner") as any;
        // const origContentTop = scrollRef?.current.offsetTop+"px"
        // const itemTop = banners?.[0]?.clientWidth > 500 ? "60px" : "40px";

        // banners.forEach((item: any, index: number)=>{
        //     item.style.zIndex=10-index;
        //     item.style.position='fixed'
        //     item.style.top=itemTop
        // });

        function handleScroll() {

            // Get all the video elements on the page
            const videos = document.querySelectorAll('.brightcove-react-player-loader video-js video') as any

            // Create a new IntersectionObserver instance
            const observer = new IntersectionObserver((entries: any) => {
                // Loop through all the observed entries
                entries.forEach((entry: any) => {
                    // Get the video element associated with the entry
                    const video = entry.target;

                    // If the video is visible, play it; otherwise, pause it
                    if (entry.isIntersecting) {
                        // console.log('intersecting:', video.paused)
                        video.play();
                    } else {
                        // console.log('not:', video.paused)
                        video.pause();
                    }
                });
            });

            // Observe each video element
            videos.forEach((video: any) => {
                observer.observe(video);
            });


            const currScroll = window.pageYOffset;
            // if(currScroll > prevScroll.current) {
            // banners.forEach((item: any, index:number)=>{
            //     if(currScroll > item.clientHeight*index) {
            //         item.style.position="relative";
            //         item.style.top="0px";
            //     }
            // })
            // }
            // else {
            // banners.forEach((item: any, index:number)=>{
            //     if(currScroll <= item.clientHeight*index) {
            //         item.style.position="fixed";
            //         item.style.top = itemTop;
            //     }
            // })
            // }
            // if (currScroll >= (banners.length-1) * banners[0]?.clientHeight) {
            //     scrollRef.current.style.position = "relative";
            //     scrollRef.current.style.top = (banners.length-1) * banners[0]?.clientHeight+"px";
            // } else {
            scrollRef.current.style.position = "relative";
            // scrollRef.current.style.top = origContentTop;
            // }
            prevScroll.current = currScroll;
        }

        window.addEventListener("scroll", handleScroll);
        // handleScroll()

        return () => {
            window.removeEventListener("scroll", handleScroll);
            // handleScroll()
        };

    }, [width]);

    return (
        <>
            {content.map((module: any, index: number) => {
                let moduleType = null as any;
                switch (module.sys?.contentType?.sys?.id) {
                    case 'heroScrollingBanner':
                        moduleType = <SkyHomeBannerCarousel bannerContent={module?.fields?.bannerContent} sendBanner={sendBanner} />
                        break;
                    case 'landingBannerPanelSlide':
                        moduleType = <Bannersection module={module?.fields} sendBanner={sendBanner} />
                        break;
                    default:
                }
                return (
                    <div key={index} >
                        {moduleType}
                    </div>
                )

            })}
            <section ref={scrollRef} className="content  w-[100vw] z-[10] bg-white">
                {content.map((module: any, index: number) => {
                    let moduleType = null as any;
                    switch (module.sys?.contentType?.sys?.id) {
                        case 'eLabelReturnBagProcess':
                            moduleType = <ELabelLayout module={module.fields} />
                            break;
                        case 'webContentSingleBanner':
                            moduleType = <SingleBanner module={module.fields} sendBanner={sendBanner} />
                            break;
                        case 'cardPanel':
                            moduleType = <CardPanel module={module.fields} sendProduct={sendProduct} />
                            break;
                        case 'componentCardSection':
                            moduleType = <BuildYourOwn module={module.fields} />
                            break;
                        case 'largeGallery':
                            moduleType = <LargeGallery module={module.fields} sendProduct={sendProduct} sendStandard={sendStandard} />
                            break;
                        case 'largeCallToAction':
                            moduleType = <LargeCallToAction module={module.fields} sendStandard={sendStandard} />
                            break;
                        case 'productHardwareSimpleBanner':
                            moduleType = <Information module={module.fields} sendBanner={sendBanner} /> // Say hello to Sky's new satellite //  Tell us what you see //Be in to win a year of Sky TV on us
                            break;
                        case 'podWebContentGallery':
                            moduleType = <Channel888 module={module.fields} sendBanner={sendBanner} /> // 2 TV
                            break;
                        case 'productHardwareFwVideoCta':
                            moduleType = <VideoSection module={module.fields} sendBanner={sendBanner} /> // Video
                            break;
                        case 'productHardwareFaqList':
                            moduleType = <FaqSection module={module.fields} sendBanner={sendBanner} /> // FAQ
                            break;
                        default:
                    }
                    return (
                        <React.Fragment key={index}>
                            {moduleType}
                        </React.Fragment>
                    )

                })}
                <Footer links={links} />
            </section>
        </>
    )
}

export default Content;