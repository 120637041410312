import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../../config';
import ReactPlayerLoader from '@brightcove/react-player-loader';

interface FullOffersProps {
    module: any;
    width: number;
    height: number;
    sendBanner: any;
}

const FullOffers: React.FC<FullOffersProps> = ({ module, width, height, sendBanner}) => {

    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];
    const videoID = module.fields.brightCoveVideoId;

    const onSuccessHandler = (success: any) => {
        success.ref.player().controlBar.hide();
    };

    // console.log(accountId, videoID, baseURL)


    React.useEffect(()=>{
        // remove the video double up
        const cleanUpVideo = document.querySelectorAll(".full-offer .video-js");
        cleanUpVideo[1]?.remove();
    })

    return (
        <div className="content-banner relative full-offer overflow-hidden bg-cover bg-no-repeat bg-top max-w-[2000px] w-full bg-[#151b17]" 
        style={{height: module.fields.brightCoveVideoId ? 400: height, backgroundImage: width > 1024 ? `url('`+module.fields.heroImage?.fields?.file?.url+`')` : `url('`+module.fields.heroImageMobile?.fields?.file?.url+`')`}}>
                {module.fields.brightCoveVideoId ?
                    <div className="mx-auto bg-[#151b17] relative z-[2] h-full lg:pt-[0]" style={{height: height, width:  width < 1024 ? width : height*1.77777}}>
                        <ReactPlayerLoader attrs={{height: width < 1024 ? width * .5625 : height+"px"}} accountId={accountId} videoId={videoID} options={{ autoplay: true, controls: false, muted: true, playsinline: true}} onSuccess={onSuccessHandler}/>
                    </div>
                : null }
            {!module.fields.brightCoveVideoId ?
            <div className="absolute bottom-0 p-[20px] w-full md:pb-[40px] lg:pb-[60px]">
                <div className="lg:mx-auto lg:max-w-[1280px]">
                    <div className="content relative">
                        {module.fields.channelOrSponsorLogos ?
                        <div className="sponsor-logos lg:flex items-center">
                            <img className="inline max-h-[40px] lg:max-h-[auto]" src={module.fields.channelOrSponsorLogos[0]?.fields.file.url} alt=""/>
                            <img className="lg:inline lg:ml-[40px] lg:mt-[20px]" src={module.fields.channelOrSponsorLogos[1]?.fields.file.url} alt=""/>
                        </div>
                        : null }
                        <div className="text-white leading-[24px] text-[18px] my-[20px] w-[85%] lg:w-[35%] lg:leading-[32px] lg:text-[28px] lg:my-[30px] sky-h2">
                            {documentToReactComponents(module.fields.heading)}
                        </div>
                        <div className="text-white leading-[24px] text-[16px] my-[20px] w-[85%] md:text-[18px] lg:w-[35%] lg:leading-[28px] lg:text-[22px] lg:mb-[30px]">
                            {documentToReactComponents(module.fields.detailParagraph)}
                        </div>
                        <div className="mb-[20px] relative -ml-[.3rem] lg:-ml[1rem]">
                         { module.fields.callToActionText ?
                            <button className="sky-button sky-button--primary-pure relative" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionText, module.fields.callToActionLink, module.fields.heroImage.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionText}</button>
                        : null }
                        { module.fields.callToActionSecondaryText ?
                            <button className="sky-button sky-button--secondary-white" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionSecondaryText, module.fields.callToActionLinkSecondary, module.fields.heroImage?.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionSecondaryText}</button>
                        : null }
                        </div>
                    </div>
                        {module.fields.copyrightParagraph ? 
                        <div className="text-white text-[12px] pt-[30px]">
                            {documentToReactComponents(module.fields.copyrightParagraph)}
                        </div>
                        : null }
                </div>
            </div>
            : null}
        </div>
    );

    
}

export default FullOffers;