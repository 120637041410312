import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DOWNLOAD_LABEL } from '../../graphql/queries/downloadLabel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ReturnBagIcon } from '../../assets/icons/icon-return-bag.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../content-modules/satellite-conent/Info';
import { segment } from '@sky-tv-group/shared';

interface PageProps {
  module: any;
  validateAccountData: any;
}

const ReturnBagFinal: React.FC<PageProps> = ({
  module,
  validateAccountData,
}) => {
  // const labelId = '6EWJFJ';
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [errorMsg, setErrorMsg] = useState('');
  const accountVerification = location.state?.accountVerification;
  const accountData = location.state?.accountData;

  let labelId =
    searchParams.get('labelId') ?? validateAccountData?.consignmentId;
  let toAddress = location.state?.toAddress ?? validateAccountData?.toAddress;
  let fromAddress =
    location.state?.fromAddress ?? validateAccountData?.fromAddress;
  let trackingID =
    location.state?.trackingID ?? validateAccountData?.trackingID;

  useEffect(() => {
    if (validateAccountData) {
      fromAddress = validateAccountData?.fromAddress;
      toAddress = validateAccountData?.toAddress;
      labelId = validateAccountData?.consignmentId;
      trackingID = validateAccountData?.trackingID;
    }
  }, [validateAccountData]);
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(DOWNLOAD_LABEL, {
    variables: { labelId, format: 'PDF' },
    skip: !labelId, // Skip query if labelId is not provided
    onError: (err) => {
      setErrorMsg(err.message);
      // showToast(err.message.replace(/^ApolloError: /, ''));
    },
  });

  const convertbase64ToBlob = () => {
    try {
      if (error) {
        setErrorMsg(error.message);
        // showToast(error.message.replace(/^ApolloError: /, ''));
        return;
      }

      if (data && data.downloadParcelLabel) {
        const base64String = data.downloadParcelLabel;
        const byteCharacters = atob(base64String);

        // Create an array to hold the byte data
        const byteArrays = [];

        // Convert the byteCharacters into byteArrays
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          byteArrays.push(new Uint8Array(byteNumbers));
        }

        // Create a Blob from the byteArrays
        const blob = new Blob(byteArrays, { type: 'application/pdf' });
        return blob;
      } else {
        setErrorMsg('An unexpected error occurred while processing the PDF.');
      }
    } catch (err) {
      setErrorMsg('An unexpected error occurred while processing the PDF.');
    }
  };

  const downloadPdf = () => {
    segment.track(`file_download_clicked`, {
      alertMessage: 'Success',
      accountVerification: accountVerification,
      account_id: accountData?.accountNumber,
      order_id: accountData?.workOrderNumber,
      email: accountData?.emailId,
      linkText: 'Download your E-label',
      linkPosition: 'content',
      linkUrl: `/returnbagprocess/returnsuccess?labelId=${labelId}`,
      trackingID: trackingID,
      returnCentre: toAddress,
      fileName: 'E-label.pdf',
    });

    // Decode the base64 string into binary data

    const blob = convertbase64ToBlob();
    // Use the FileSaver library to download the Blob as a PDF
    blob && saveAs(blob, 'E-label.pdf');
  };

  const printPdf = () => {
    segment.track(`file_download_clicked`, {
      alertMessage: 'Success',
      accountVerification: accountVerification,
      account_id: accountData?.accountNumber,
      order_id: accountData?.workOrderNumber,
      email: accountData?.emailId,
      linkText: 'Print your E-label',
      linkPosition: 'content',
      linkUrl: `/returnbagprocess/returnsuccess?labelId=${labelId}`,
      trackingID: trackingID,
      returnCentre: toAddress,
    });

    // Convert base64 string to binary data
    const pdfBlob = convertbase64ToBlob();
    // Create an object URL for the Blob
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob!);

      // Open the PDF in a new tab for printing
      const printWindow = window.open(pdfUrl);
      printWindow!.onload = () => {
        printWindow!.print();
      };
    }
  };
  return (
    <div className="p-8 md:container m-auto md:pb-10 lg:px-20 pt-10 mt-4 text-navy">
      {/* Header Section */}
      <h1
        className="text-3xl font-bold text-gray-900"
        style={{ fontFamily: 'MarkPro-Black SKY' }}
      >
        {module?.finalPageTitle}
      </h1>

      <div className="flex items-center gap-2 mt-4">
        <ReturnBagIcon className="w-35 md:w-12  h-16 text-blue-600" />
        <div className="flex items-center  whitespace-break-spaces text-16px">
          {documentToReactComponents(module?.finalPageSubTitle, options)}
        </div>
      </div>

      {/* Address & Return Details */}
      <div className="mt-10 space-y-6">
        <div>
          <h2
            className="sky-h3-bold text-gray-900 font-extr"
            style={{ fontSize: '22px', fontWeight: '900' }}
          >
            Your physical address
          </h2>
          <p
            className="sky-h6-reg sky-text-midnight mt-3"
            style={{ fontSize: '16px' }}
          >
            {fromAddress}
          </p>
        </div>

        <div>
          <h2
            className="sky-h3-bold text-gray-900 mt-3"
            style={{ fontSize: '22px', fontWeight: '900' }}
          >
            Your nearest recycling centre
          </h2>
          <p
            className="sky-h6-reg sky-text-midnight mt-3"
            style={{ fontSize: '16px' }}
          >
            {toAddress}
          </p>
        </div>

        <div>
          <h2
            className="sky-h3-bold text-gray-900 mt-3 mb-2"
            style={{ fontSize: '22px', fontWeight: '900' }}
          >
            NZ Post drop-off address
          </h2>
          <a
            href="https://www.nzpost.co.nz/tools/address-postcode-finder"
            className="font-semibold text-blue-600 underline"
            target="_blank"
            style={{ fontSize: '16px' }}
          >
            Find your nearest drop-off address here
          </a>
        </div>

        <div>
          <h2
            className="sky-h3-bold text-gray-900 mt-3 mb-2"
            style={{ fontSize: '22px', fontWeight: '900' }}
          >
            Track your parcel here{' '}
            <span style={{ fontSize: '16px', fontWeight: '400' }}>
              [Tracking number found under the barcode on your e-label]
            </span>
          </h2>
          {/* <a
            href={`https://www.nzpost.co.nz/tools/tracking?trackid=${trackingID}`}
            className="font-semibold text-blue-600 underline mt-3"
            target="_blank"
            style={{ fontSize: '16px' }}
          >
            [Found under the barcode on your e-label]
          </a> */}

          <a
            href={`https://www.nzpost.co.nz/tools/tracking?trackid=${trackingID}`}
            className="font-semibold text-blue-600 underline mt-3"
            target="_blank"
            style={{ fontSize: '16px' }}
          >
            NZ Post tracking number
          </a>
        </div>
      </div>
      {errorMsg && <p className="text-red-500 mt-2">{errorMsg}</p>}

      {/* Action Buttons */}
      <div className="flex flex-col md:flex-row justify-left mx-auto my-2 pt-4 space-y-4 md:space-y-0 md:space-x-4">
        {module?.isDownload && (
          <button
            disabled={errorMsg ? true : false}
            onClick={printPdf}
            className={`order-2 mt-4 md:mt-0 w-full md:w-1/4 md:order-1  py-2 rounded-full font-semibold ${
              errorMsg
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                : window.screen.width > 475
                ? 'bg-blue-600 text-white hover:bg-blue-700 cursor-pointer'
                : 'border-blue-600 border text-blue-600 hover:bg-blue-100 cursor-pointer'
            } transition`}
          >
            Print your E-label
          </button>
        )}
        {module?.isPrint && (
          <button
            disabled={errorMsg ? true : false}
            onClick={downloadPdf}
            className={`order-1  w-full md:w-1/4 md:order-2 ${
              errorMsg
                ? 'border-gray-400 text-gray-200 cursor-not-allowed'
                : window.screen.width > 475
                ? 'border-blue-600 text-blue-600 hover:bg-blue-100 cursor-pointer'
                : 'bg-blue-600 text-white hover:bg-blue-700 cursor-pointer'
            } border  py-2 rounded-full font-semibold transition md:w-/4`}
          >
            Download your E-label
          </button>
        )}
      </div>
    </div>
  );
};

export default ReturnBagFinal;

