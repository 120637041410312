import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@sky-tv-group/style3/main.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Home, Pages, ErrorPage } from './pages';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from './config';
import { ApolloProvider } from '@apollo/client';
import client from './graphql/apollo-client';
import { ELabelLayout } from './components/nzpost/ELabelLayout';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/:uid',
        element: <Pages />,
      },
      {
        path: '/returnbagprocess/*',
        element: <ELabelLayout />,
        errorElement: <ErrorPage />,
      },
    ],
  }
]);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: SPLIT_AUTHORIZATION_KEY,
    key: SPLIT_KEY,
  },
};

root.render(
  <React.StrictMode>
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </SplitFactory>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();