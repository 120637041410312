import React from 'react';
import { AddIcon, ButtonLoader, CheckIcon, EditIcon } from './icon';
import cx from 'classnames';
export interface ButtonProps {
  variant?: 'primary' | 'secondary';
  dataTestId?: string;
  colorMode?:
    | 'special'
    | 'dark'
    | 'light'
    | 'error'
    | 'warning'
    | 'success'
    | 'offer'
    | 'inverse'
    | 'midnight'
    | 'dull'
    | 'disabled'
    | 'pure'
    | 'pink';
  icon?: 'add' | 'check' | 'addBluePure' | 'edit' | 'editBluePure' | 'loading';
  small?: boolean;
  iconOnLeft?: boolean;
  additionalClasses?: string;
}
const iconMap = {
  add: <AddIcon />,
  addBluePure: <AddIcon color={'#0057FF'} />,
  check: <CheckIcon />,
  edit: <EditIcon />,
  editBluePure: <EditIcon color={'#0057FF'} />,
  loading: <ButtonLoader />,
};

export const Button = ({
  disabled,
  dataTestId,
  className = '',
  variant = 'primary',
  colorMode = 'light',
  icon,
  style,
  children,
  small,
  iconOnLeft,
  additionalClasses,
  ...rest
}: ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  const cn = cx(`sky-button--${variant}-${colorMode}`, { small: small });
  const iconComp = icon ? iconMap[icon] : null;
  const iconAlignment = iconOnLeft ? 'justify-start mr-2' : 'justify-end';

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      className={`sky-button ${cn} ${className} ${additionalClasses}`}
      style={{ ...style }}
      {...rest}>
      <div className="flex justify-between items-center">
        {iconOnLeft && <div className={`flex-1 flex ${iconAlignment}`}>{iconComp}</div>}
        <div className="flex-1"></div>
        <div className="flex-auto">{children}</div>
        {!iconOnLeft && <div className={`flex-1 flex ${iconAlignment}`}>{iconComp}</div>}
      </div>
    </button>
  );
};
