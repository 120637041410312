import React from "react";
import { useQuery, gql } from "@apollo/client";

export const SEARCH_ADDRESS = gql`
  query SearchAddress($query: String!, $count: Float!) {
    searchAddress(query: $query, count: $count) {
      id
      name
      address
      city
      postalCode
    }
  }
`;