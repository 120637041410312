import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { REACT_APP_GRAPHQL_URL } from "../config";

// Initialize Apollo Client
const client = new ApolloClient({
  uri: REACT_APP_GRAPHQL_URL, // Replace with your GraphQL endpoint
  cache: new InMemoryCache(),
});

export default client;
