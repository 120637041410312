import {
  HomePagePromotionalCard,
  HomePagePromotionaCardDetails,
} from './homepagepromotionalcard';
import {
  IPopUpContentConfig,
  JSONConfigService,
  KonakartService,
  T_PlanSelector,
  T_Product,
  redirectToSubdomain,
} from '@sky-tv-group/shared';
import { Button } from '../../../local/button';

export interface HomePageProductCardProps {
  card: HomePagePromotionaCardDetails;
  bgColor?: string;
  kkProduct?: T_Product;
  kkService?: KonakartService;
  disabled?: boolean;
  subscribed?: boolean;
  fixedWidth?: number;
  isStarterInCart?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
  handleProductCardCTA?: (buttonText: string) => void;
  compactMode?: boolean;
  inCart?: boolean;
  configService?: JSONConfigService;
  plan?: T_PlanSelector;
  selectedTuiAddress?: string;
  discountText?: string;
}

export function HomePageProductCard({
  card,
  bgColor,
  compactMode,
  fixedWidth,
  handleProductCardCTA,
}: HomePageProductCardProps) {
  const productDetails = {
    bgColor: bgColor ?? card.bgColor ?? '#1F0124',
    billingFrequency: card.billingFrequency,
    campaign: card.campaign,
    disclaimer: card.disclaimer,
    discountText: card.discountText,
    discountedPrice: card.discountedPrice,
    fromPrice: card.fromPrice,
    imageSrc: card.imageSrc,
    lozengeText: card.lozengeText,
    sku: card.sku,
    occurrenceType: card.occurrenceType,
    subtitle: card.subtitle,
    termsAndConditions: card.termsAndConditions,
    title: card.title,
    type: card.type,
    coupon: card.coupon,
    descriptionList: card.descriptionList,
    iconList: card.iconList,
    copyrightText: card.copyrightText,
    addLink: card.addLink,
    addLinkText: card.addLinkText,
    detailsText: card.detailsText,
    detailsLink: card.detailsLink,
    promotionImage: card.promotionImage,
  };


  return (
    <HomePagePromotionalCard
      cardType="PRODUCT"
      compactMode={compactMode}
      card={productDetails}
      buttons={
        <div
          className={`flex flex-row ${compactMode ? '' : 'mt-sky-lg'
            } w-full p-5 justify-center`}
        >
          {productDetails?.detailsText && (
            <Button
              variant="secondary"
              colorMode="pure"
              style={{ width: '100%', whiteSpace: 'normal' }}
              onClick={() => {
                if (handleProductCardCTA) {
                  handleProductCardCTA(productDetails.detailsText || '');
                }
                if (productDetails?.detailsLink) {
                  window.open(productDetails?.detailsLink, '_self')?.focus();
                }
              }}
            >
              {productDetails?.detailsText}
            </Button>
          )}
          {productDetails?.addLinkText &&
            <Button
              variant="primary"
              colorMode="pure"
              style={{ width: '100%', whiteSpace: 'normal' }}
              onClick={() => {
                if (handleProductCardCTA) {
                  handleProductCardCTA(productDetails.addLinkText || '');
                }
                if (productDetails.addLink) {
                  window.open(productDetails.addLink, '_self')?.focus();
                } else {
                  redirectToSubdomain('ICE', `?skus=${card.sku}`, true);
                }
              }}
            >
              {productDetails?.addLinkText}
            </Button>
          }
        </div>
      }
      bgColor={card.bgColor ?? bgColor}
      fixedWidth={fixedWidth}
    />
  );
}
