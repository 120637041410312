import React, { useState, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../content-modules/satellite-conent/Info';

interface PageProps {
  galleryContent: any;
}
const StepsComponent: React.FC<PageProps> = ({ galleryContent }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Detect the active step based on scroll position
  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const stepWidth = scrollRef.current.scrollWidth / galleryContent.length;
      const index = Math.round(scrollLeft / stepWidth);
      setActiveIndex(index);
    }
  };
  return (
    <div className="mt-6 relative">
      {/* Scrollable Steps Container */}
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="overflow-x-auto md:overflow-visible scrollbar-hi"
      >
        <div className="flex flex-nowrap md:flex-wrap justify-left items-center gap-6 md:pl-0">
          {galleryContent.map((item: any, index: number) => (
            <div
              key={index}
              style={{ minHeight: '320px', minWidth: '300px' }}
              className="flex flex-col p-4 border rounded-lg  w-72 flex-shrink-0 bg-white shadow-lg"
            >
              {/* Number + Icon Wrapper */}
              <div className="flex justify-between w-full">
                {/* Rounded Number */}
                <div className="flex items-center justify-center w-10 h-10 text-lg font-bold text-blue-light border-2 border-blue-light rounded-full">
                  {item?.fields?.promotionText}
                </div>
                {/* Icon */}
                <div className="flex items-center justify-center flex-grow mt-3">
                  <img
                    className="w-25 h-25"
                    src={item?.fields?.contentThumbnail?.fields?.file?.url}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
                <div className="flex w-10 h-10"></div>
              </div>

              {/* Title */}
              <h3 className="items-center text-center mt-4 text-lg font-semibold">
                {item?.fields?.contentTitle}
              </h3>

              {/* Description */}
              <p className="items-center text-center text-gray-600 mt-2 text-16px">
                {documentToReactComponents(
                  item?.fields?.contentDescription,
                  options,
                )}
                {/* {description}{" "}
                {linkText && linkUrl && (
                  <a href={linkUrl} className="text-blue-600 underline">
                    {linkText}
                  </a>
                )} */}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Dots Pagination */}
      <div className="flex justify-center mt-4 space-x-2 md:hidden">
        {galleryContent.map((_: any, index: number) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full ${
              activeIndex === index ? 'bg-blue-600' : 'bg-gray-300'
            } transition-all duration-300`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default StepsComponent;
