import * as React from 'react';
import { FullOffers, FullPrice, ThirtySixty, FiftyFifty } from '../banner-components'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

interface SingleBannerProps {
    module: any
    sendBanner: any
}

const SingleBanner: React.FC<SingleBannerProps> = ({ module, sendBanner }) => {

    const { width, height } = useWindowDimensions();

    const areaWidth = width > 2000 ? 2000 : width;
    const bannerHeight = width > 1024 ? Math.round(areaWidth*0.409058740268931) : width > 500 ? (height*.75)-60 : height-40;  

    return (
        <section id={module.bannerName.replace(/ /g, "-").toLowerCase()} className="single-banner relative w-full z-[5] overflow-hidden max-w-[2000px] mx-auto" >
                {module.bannerContent.map((item: any, index: number)=>{
                    switch(item.fields?.layoutOptions[0]) {
                        case 'Sky Background - Half Width':
                            return <FiftyFifty module={item} key={index} height={bannerHeight} width={areaWidth} sendBanner={sendBanner}/>
                        case 'Sky Background - Third Width':
                            return <ThirtySixty module={item} key={index} height={bannerHeight} width={areaWidth} sendBanner={sendBanner}/>
                        case 'Sky Background - Full Width Price':
                            return <FullPrice module={item} key={index} height={bannerHeight} width={areaWidth} sendBanner={sendBanner}/>
                        case 'Image Background - Offers':
                            return <FullOffers module={item} key={index} height={bannerHeight} width={areaWidth} sendBanner={sendBanner}/>
                        default:
                            return null
                    }
                })}
        </section>
        );
    }   

export default SingleBanner;