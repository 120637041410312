// constants.ts
export const CARRIER = 'COURIERPOST';
export const SENDER_REFERENCE_1_PREFIX = 'Order Number';
export const SENDER_REFERENCE_2 = 'Sales';
export const ACCOUNT_NUMBER = '92306955';

export const RECEIVER_DETAILS_AUCKLAND_DPID = 2983585;
export const RECEIVER_DETAILS_WELLINGTON_DPID = 3043839;

export const RECEIVER_DETAILS_AUCKLAND_ADDRESS = '5 Southpark PlacePenrose, Auckland 1061';
export const RECEIVER_DETAILS_WELLINGTON_ADDRESS = '2/101 Gracefield RoadGracefield, Lower Hutt 5010';
export const SITE_CODE = 94371;

// export const PARCEL_DETAILS = {
//   service_code: 'CPOLP',
//   return_indicator: 'OUTBOUND',
//   description: 'Medium Box',
//   dimensions: {
//     length_cm: 30,
//     width_cm: 30,
//     height_cm: 30,
//     weight_kg: 3,
//   },
// };
export const PICKUP_ADDRESS = {
  country_code: 'NZ',
};

export const DELIVERY_ADDRESS = {
    dpid: RECEIVER_DETAILS_AUCKLAND_DPID,//1371032,
    country_code: 'NZ',
  };
export const RECEIVER_DETAILS_AUCKLAND = {
    name: 'Computer Recycling',
    phone: '+095255518',
  };
  export const RECEIVER_DETAILS_WELLINGTON = {
    name: 'ITRecycla',
    phone: '+045896293',
  };

